import React from 'react'
import LandingPage from '../assets/TempLandingPageJPG1044x798.JPG'
import '../styles/Home.css'

function Home() {
  return (
    <div className='home'>
      <div className='headerContainer' style={{backgroundImage: `url(${LandingPage})`, height: '100%', width: '100%', backgroundPosition: 'center',backgroundSize: 'auto',backgroundRepeat: 'no-repeat'}}>
        
      </div>
    </div>
  )
}

export default Home
