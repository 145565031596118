import React from 'react'
import logo from '../assets/ANC256x96.png'
import '../styles/Navbar.css'

function navbar() {
  return (
    <div className='navbar'>
        <div className='leftSide'>
            <img src={logo} alt={'ANC'}></img>
            <h1>Security Starts with You!</h1>
        </div>
        <div className='rightSide'>
        <h2>ancsite@anoblecreation.com</h2>
        </div>
      
    </div>
  )
}

export default navbar
